.footer-box{
    padding: 30px 0 10px 0;
    width: 100%;
    background-color: rgb(23, 23, 23);
}

.footer-cont
{
    display: flex;
    padding: 30px 0 10px 0;
    width: 80%;
    margin: auto;
    color: white;
}

.footer-items{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footer-logo{
    width: 100px;
    height: 100px;
}

.footer-msg{
    display: flex;
    flex-direction: column;
    max-width: 300px;
}

.footer-msg-header{
    margin: 0;
}

.footer-msg-p{
    color: rgb(206, 203, 203);
}

.footer-store-info{
    display: flex;
    width: 50%;
    justify-content: space-around;
}

.footer-sitemap{
    margin: 0 auto;
    display: flex;
    width: 50%;
    justify-content: space-around;
}

.footer-about-us{
    display: flex;
    flex-direction: column;
}

.footer-legal{
    display: flex;
    flex-direction: column;
}

.footer-link{
    margin: 10px 0;
    color: rgb(234, 234, 234);
}

.footer-link:visited{
    color: rgb(234, 234, 234);
}

.footer-company-details{
    margin: auto;
    text-align: center;
    color: white;
    font-size: 14px;
}

@media only screen and (max-width: 600px)
{
    div.footer-cont{
        padding: 0;
    }

    div.footer-items{
        flex-direction: column;
        padding: 10px;
    }

    div.footer-store-info{
        width: 100%;
        gap: 10px;
    }

    ul.footer-sitemap{
        width: 100%;
        gap: 20px;
        padding-left: 0;
    }
}