.n-b-top{
    background-color: rgb(13, 13, 13);
}

.n-b-top-item{
    display: flex;
    gap: 5px;
    color: rgb(177, 177, 177);
    cursor: pointer;
}

.n-b-top-item:visited{
    color: rgb(177, 177, 177);
}

.n-b-currency-arrow{
    width: 10px;
    margin: auto;
    display: block;
}

.n-b-top-contents{
    width: 90%;
    margin: auto;
    padding: 5px 0;
    gap: 25px;
    font-size: 14px;
    display: flex;
    justify-content: right;
}

.notif{
    --border-bottom-size: 1px;
    --border-angle: 0turn;
    padding: 20px;
    background-image: conic-gradient(
        from var(--border-angle),
        #213 30%,
        #112 ,
        #213
      ),
      conic-gradient( transparent 20%, rgb(94, 91, 180), rgb(82, 66, 204));
    background-size: calc(100% - (var(--border-size) * 2))
        calc(100% - (var(--border-size) * 2)),
      cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: none;
    animation: bg-spin 5s linear infinite;
}

.n-b-cont{
    position: fixed;
    box-sizing: border-box;
    z-index: 1;
    background-color: rgb(36, 36, 36);
    color: rgb(177, 177, 177);
    width: 100%;
    left: 0;
    top: 0;
    margin: auto;
}

.n-b-flex{
    width: 90%;
    margin: auto;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
}

.store-logo{
    height: 50px;
    width: 50px;
}

.store-name{
    color: rgb(234, 234, 234);
    font-size: 20px;
}

.pn-nav-bar{
    margin: auto;
}

.mc-nav-bar{
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    padding: 2px 0 2px 0;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
}

.mc-nav-left-side{
    gap: 10px;
    display: flex;
}

.mc-nav-bar-name{
    display: flex;
    margin: auto 0;
}


.mc-nav-bar-name>div{
    margin: auto 10px;
}

.mc-nav-bar-main{
    display: flex;
    margin: auto 0 ;
    gap: 10px;
    justify-content: space-between;
}

.mc-nav-categories{
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    padding: 0;
    list-style: none;
    margin: auto;
}

.pn-nav-bar-item
{
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    margin: auto 5px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    color: rgb(228, 228, 228);
}

.pn-nav-bar-item:hover{
    border-radius: 5px;
    background-color: rgba(84, 82, 83, 0.8);
}

.pn-nav-bar-item>a{
    color: rgb(228, 228, 228);
}

.pn-nav-bar-item>a:visited{
    color: rgb(228, 228, 228);
}

.n-b-logged-in{
    display: flex;
    gap: 10px;
}

.n-b-action-item-cont{
    display: flex;
    background-color: rgb(76, 76, 76);
    color: rgb(255, 255, 255);
    padding: 10px 10px;
    border-radius: 8px;
    margin: auto;
    gap: 7px;
    cursor: pointer;
}

.profile-text{
    font-size: 16px;
    margin: auto;
}

.currency-selector{
    position: absolute;
    top: 26px;
    z-index: 2;
    background-color: rgb(64, 64, 68);
    width: 200px;
    padding: 5px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.select-item{
    padding: 10px 15px;
    font-size: 16px;
}

.select-item:hover{
    cursor: pointer;
    color: white;
}

@media only screen and (max-width: 500px)
{
    nav.mc-nav-bar{
        padding: 5px 0;
    }
}

@media only screen and (max-width: 1000px){
    div.n-b-flex{
        width: 95%;
    }

    div.n-b-top-contents{
        width: 95%;
        margin:  auto;
    }

    .pn-nav-bar>ol{
        display: none;
        flex-wrap: wrap;
    }
}

@media (min-width: 1026px) {
    .pn-nav-bar {
        display: block;
    }
}

@media only screen and (max-width: 1025px) {
    .pn-nav-bar{
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    img.store-logo{
        width: 50px;
        height: 50px;
        border-radius: 10px;
    }
  
    div.mc-nav-bar-main>a{
        margin: auto;
    }
}