div.i-p-page{
    height: 100vh;
    padding-top: 30px;
    box-sizing: border-box;
    background-color: black;
}

div.i-p-cont-item{
    border-radius: 10px;
    box-sizing: border-box;
}

nav.i-p-nav{
    display: flex;
    width: 100%;
    border-bottom: 3px solid gray;
}

div.i-p-nav-item{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px 20px;
    text-align: center;
    font-weight: bold;
}

div.i-p-nav-item:hover{
    background-color: gray;
    color: white;
    cursor: pointer;
}

.selected{
    color: black;
    cursor: pointer;
}

div.i-p-nav-item>i{
    font-size: 15px;
}

div#order-status{
    margin: auto 0;
    padding: 7px 15px;
    font-size: 16px;
    color: white;
    border-radius: 5px;
}

div.unpaid{
    background-color: rgb(156, 156, 156);
}

div.restricted{
    background-color: #cf2222;
}

div.paid{
    background-color: rgb(61, 186, 61);
}

h4.i-p-i-h{
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
    margin: 10px 0;
    font-weight: bold;
}

h4.i-p-i-c{
    padding: 0;
    margin: 5px 0;
    color: #e3e3e3;
    font-size: 15px;
    font-style: italic;
}

div.i-p-cont{
    display: block;
    margin: 0 auto;
    width: 75%;
    padding: 10px 10px 20px;
    box-sizing: border-box;
}

div.i-p-order-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

div.i-p-order-details>div{
    box-sizing: border-box;
    display: flex;
    margin: 0 15px;
}

div.i-p-details
{
    display: flex;
    min-height: 600px;
    flex-direction: row;
}

div.i-p-info{
    width: 30%;
    display: inline-block;
}

div.i-p-d-p-h{
    display: flex;
    justify-content: space-between;
}

.i-p-o-d-order-id-cont{
    display: flex;
    margin: auto 0;
    padding: 0;
    gap: 5px;
    font-size: 16px;
    color: rgb(78, 78, 78);
}

.i-p-o-d-order-id-title{
    color: rgb(178, 178, 178);
}

.i-p-o-d-h-id{
    cursor: pointer;
    margin: auto 0;
    font-weight: bold;
    font-size: 16px;
    color: rgb(178, 178, 178);
}

div.i-p-o-i-cont{
    padding: 10px 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: rgb(105, 105, 105);
}

div.i-p-o-d-cont{
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    gap: 10px;
    width: 100%;
    max-height: 750px;
    overflow: auto;
    flex-direction: column;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: rgb(243, 243, 243);
    color: #4A4A4A;
    font-size: 14px;
}

.items-preview-cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

div.i-p-o-in-cont{
    margin-top: 20px;
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 25px;
    border-radius: 5px;
    background-color: rgb(228, 228, 228);
    box-sizing: border-box;
    font-size: 14px;
}

div.i-p-o-d-product{
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    letter-spacing: 1px;
    line-height: 20px;
    overflow-y: auto;
    overflow-x: auto;
    background-color: rgb(245, 245, 245, 0.7);
}

div.i-p-d-instructions{
    max-height: 225px;
}

code{
    background-color: gray;
}

div.i-p-o-d-actions{
    padding-top: 10px;
    border-top: 2px solid rgb(255, 255, 255);
    margin-top: auto;
}

button.i-p-o-d-action{
    border-radius: 5px;
    padding: 13px 12px 13px 10px;
    font-size: 14px;
    display: block;
    margin: auto;
    border: none;
}

button.i-p-o-d-action:hover{
    cursor: pointer;
}

.action-copy{
    background-color: #91b2f0;
    color: #e4e4e4;
}

.action-discord{
    background-color: #404eed;
    color: #e4e4e4;
}

.action-revoke{
    color: white;
    background-color: #de1b1b;
}

.email-pass-only{
    display: none;
}

.fa-info-title{
    font-weight: bold;
    padding: 4px 0;
}

.fa-info-details
{
    padding: 10px 10px;
    font-size: 16px;
    position: relative;
    border-radius: 5px;
    background-color: #adadad;
    color: white;
    display: inline-block;
}

.clipboard-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    opacity: 0;
    display: none;
    cursor: pointer;
}

.claim-section{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.claim-btn{
    padding: 13px 15px;
    background-color: rgb(68, 164, 68);
    color: white;
    font-size: 20px;
    border-radius: 5px;
}

.fasm-body{
    line-height: 1.5;
    border-radius: 5px;
    color: rgb(52, 50, 50);
    width: 100%;
    box-sizing: border-box;
}

.fasm-desc-header{
    font-size: 18px;
    font-weight: bold;
}

.fasm-note-title{
    font-weight: bold;
    font-size: 16px;
    padding: 4px 0;
}

.fasm-note{
    color: rgb(0, 0, 0);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid black;
    border-top: 1px solid black;
}

.fasm-alt-cont{
    display: flex;
    justify-content: space-between;
}

.fasm-info-cont{
    display: flex;
    gap: 10px
}

.fasm-alt-account-number{
    font-size: 24px;
}

.fasm-alt-actions{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
}

.fasm-alt-claimed, .fasm-alt-claim-now
{
    color: white;
    border-radius: 5px;
    margin-top: auto;
    font-size: 18px;
    padding: 10px 15px;
    box-sizing: border-box;
}

.fasm-alt-claimed:hover{
    cursor: not-allowed;
}

.fasm-alt-claim-now:hover{
    cursor: pointer;
    box-shadow: 0px 1.0px 5px rgb(115, 115, 115);
}

.fasm-alt-claimed{
    background-color: #4A4A4A;
}

.fasm-alt-claim-now
{
    background-color: #6bb34f;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

.fade-in {
    animation: fadeIn 0.2s ease-in forwards; /* Play the fadeIn animation over 1 second */
}

.text-to-copy{
    color: white;
}

.fa-info-details:hover .text-to-copy {
    opacity: 0.3;
}

.text-to-copy:visited{
    color: white;
}

.fa-info-details:hover .clipboard-icon {
    display: block;
}

.fa-info-details:hover{
    cursor: pointer;
}

.i-p-o-d-serials{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.i-p-o-d-s-g-back{
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

@media only screen and (max-width: 450px)
{
    div.i-p-o-d-cont{
        padding: 10px 5px;
    }

    div.i-p-page{
        padding-top: 70px;
    }
}

@media only screen and (max-width: 750px)
{
    .i-p-o-d-order-id-cont{
        flex-direction: column;
    }

    div.fasm-alt-cont{
        flex-direction: column;
        gap: 10px
    }

    div.i-p-order-details>div{
        margin: 0;
    }

    div.i-p-details{
        flex-direction: column;
    }

    div.i-p-o-i-cont{
        border-radius: 0 0 5px 5px;
    }
    
    div.i-p-o-d-cont{
        border-radius: 5px 5px 0 0 ;
    }

    div.i-p-nav-item{
        padding: 20px 0;
    }

    div.i-p-order-details{
        display: flex;
        flex-direction: column;
    }

    div.i-p-info{
        margin: 0;
        width: 100%;
        box-sizing: border-box;
    }

    div.i-p-o-in-cont{
        width: 100%;
    }

    .fa-info-details{
        width: 100%;
        box-sizing: border-box;
    }
}

@media only screen and (max-width: 1300px) {
    div.i-p-cont{
        width: 100%;
    }
}