.verify-cont{
    width: 450px;
    padding: 30px;
    box-sizing: border-box;
    margin: 150px auto;
    border: 1px silver solid;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: center;
    background-color: rgb(248, 248, 248);
}

.verify-header-cont{
}

.verify-img-cont{
    width: 60px;
    height: 60px;
    margin: auto;
    border-radius: 30px;
}

.verify-img{
    width: 45px;
    height: 45px;
    margin-bottom: 10px;
}

.verify-header{
    margin: 0;
    font-size: 18px;
    color: rgb(88, 88, 88);
}

.verify-message{
    font-size: 14px;
    margin: 10px;
    color: rgb(137, 137, 137);
}

.verify-instructions{
    margin: 10px;
    font-size: 13px;
    color: rgb(95, 95, 95);
}

.verify-resend-btn{
    background-color: rgb(156, 156, 156);
    color: white;
    padding: 13px;
    width: 60%;
    margin: auto;
    font-size: 13px;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(103, 210, 87);
}

.verify-resend-btn:hover{
    opacity: 0.8;
}

.verify-i-item{
    margin: 5px;
}

.verify-contact{
    font-weight: bold;
    color: rgb(103, 210, 87);
}

.verify-contact:visited{
    color: rgb(114, 220, 97);
}

.verify-status-message{
    color: gray;
    font-size: 14px;
    margin: 10px;
    display: none;
}

.verify-footer{
    width: 100%;
    margin: 20px 0 0;
    text-align: center;
    font-size: 14px;
    color: rgb(149, 149, 149);
}