.support-answer-page {
    line-height: 1.5rem;
    width: 100%;
}

.support-answers-section-header {
    color: rgb(57, 57, 57);
}

.support-answers-ul {
    line-height: 1.5rem;
}

.support-breadcrumb {
    display: flex;
    font-weight: bold;
    margin: 5px 0 15px;
    cursor: pointer;
}

.breadcrumb {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 30px 0;
    padding: 0;
}

.breadcrumb-item {
    margin-right: 10px;
    font-size: 18px;
    color: rgb(33, 33, 33);
    font-weight: bold;
}

.breadcrumb-item :visited{
    color: rgb(33, 33, 33);
    font-weight: normal;
}

.breadcrumb-item::after {
    content: '>';
    margin-left: 10px;
    color: black;
}

.breadcrumb-item:last-child::after {
    content: '';
}