.tos{
    width: 55%;
    margin: auto;
    padding: 60px 0 50px;
    text-align: left;
}

.tos-header{
    width: 100%;
    margin: 0;
}

.tos-description{
    width: 100%;
    margin: 5px 0;
}

.tos-section-header{
    font-size: 18px;
}

p.terms{
    margin: 0;
    font-size: 16px;
    font-weight: normal;
}

@media only screen and (max-width: 1150px) {
    .tos{
        width: 90%;
        padding-top: 120px auto;
    }
}