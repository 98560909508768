.hover-footer{
    bottom: 0;
    position: fixed;
    z-index: 2;
}

.hf-item{
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    background-color: white;
    box-shadow: 0px 1.0px 10px rgb(122, 122, 122);
    animation-duration: 3s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: bounce;
    animation-timeline: auto;
}

.hf-item:hover{
    cursor: pointer;
    margin-bottom: 10px;
}

.hf-items-cont{
    padding: 30px 15px;
}

.hf-img{
    display: block;
    margin: auto;
    width: 50px;
    height: 37.5px;
}

@media only screen and (max-width: 500px) {
    div.hf-item{
        background-color: rgb(162, 162, 162);
    }
}