.support-page-cont{
    width: 100%;
}

.support-navbar{
    width: 100%;
    padding: 60px 0 40px;
    box-sizing: border-box;
    background-color: rgb(215, 215, 215);
}

.support-navbar-inner{
    display: flex;
    gap: 20px;
    margin: auto;
    width: 80%;
    justify-content: space-between;
}

.support-navbar-item{
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid rgb(181, 181, 181);
    width: 300px;
    display: flex;
    background-color: rgb(246, 246, 246);
    gap: 20px;
    color: rgb(225, 225, 225);
    cursor: pointer;
}

.support-navbar-item-text{
    margin: auto 0;
    color: rgb(128, 128, 128);
    font-size: 16px;
}

.support-navbar-item-img{
    margin: auto 0;
    width: 40px;
    height: 40px;
}

.support-navbar-item:hover{
    border: 1px rgb(128, 128, 128) solid;
    background-color: rgb(53, 53, 53);
    color: white;
    box-shadow: 0px 1.0px 5px rgb(80, 80, 80);
}

.support-main
{
    width: 75%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    gap: 30px;
    padding: 50px 0;
}

.support-questions-cont{
    width: 90%;
}

.support-questions-header{
    width: 100%;
    line-height: 3rem;
    font-size: 30px;
    font-weight: bold;
    color: rgb(48, 48, 48);
}

.support-main-questions-cont{
    padding: 0;
}

.support-main-questions-item{
    padding: 20px 10px;
    width: 100%;
    display: block;
    color: rgb(80, 80, 80);
    font-size: 19px;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(150, 150, 150);
}

.support-main-questions-item:hover{
    background-color: rgb(204, 204, 204);
}

.support-contact-cont{
    display: flex;
    padding: 15px;
    border: 1px solid silver;
    border-radius: 5px;
    background-color: rgb(230, 230, 230);
    min-width: 300px;
    max-width: 350px;
    height: 230px;
    box-sizing: border-box;
    flex-direction: column;
    gap: 20px;
}

.support-contact-info-header{
    font-size: 20px;
}

.support-contact-item{
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.support-contact-item-header{
    color: rgb(77, 77, 77);
    font-size: 16px;
}

.support-contact-item-content{
    color: rgb(77, 77, 77);
    font-size: 16px;
}

.support-contact-discord{
    width: 100%;
    background-color: rgb(90,100,249);
    color: rgb(255,255,255);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.support-contact-email{
    width: 100%;
    background-color: rgb(107, 107, 107);
    color: rgb(255,255,255);
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 1150px)
{
    .support-main{
        flex-direction: column-reverse;
    }
    /* .support-navbar-inner{
        flex-direction: column;
    } */
}

@media only screen and (max-width: 1050px)
{
    .support-main{
        padding-top: 90px;
        width: 95%;
        gap: 15px;
    }

    .support-navbar{
        display: none;
    }

    .support-questions-cont{
        width: 100%;
    }

    .support-main-questions-cont{
        padding: 0;
    }
}

@media only screen and (max-width: 500px)
{
    .support-contact-cont{
        margin: auto;
    }
}