.securer-cont{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgb(0,0,0, 0.9);
    top: 0;
    bottom: 0;
    left: 0;
}

.securing-modal{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 120px auto;
    min-width: 500px;
    max-width: 500px;
    width: 40%;
}

.securer-help{
    color: white;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgb(254, 254, 254);   
}

.securer-items{
    color: white;
    box-sizing: border-box;
    display: flex;
    text-align: left;
    flex-direction: column;
    padding: 20px;
    background-color: rgb(0, 0, 0);
    border-radius: 5px;
    box-shadow: 0px 0px 6px rgb(254, 254, 254);
}

.securer-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.securer-logo{
    display: flex;
    gap: 5px;
}

.securer-img{
    width: 50px;
    height: 50px;
}

.securer-name{
    margin: auto 0;
    letter-spacing: 1px;
}

.securer-cancel{
    margin: auto 0;
    font-size: 20px;
    font-weight: bold;
}

.securer-cancel:hover{
    cursor: pointer;
}

.startprocess-modal-header{
    margin: 10px 0;
}

.startprocess-header{
    margin: 0;
    font-size: 22px;
}

.securer-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.startprocess-input{
    width: 100%;
    padding: 7px 5px;
    box-sizing: border-box;
    outline: none;
    background: white;
    border-radius: 2px;
    border: none;
    border-bottom: 1px rgb(255, 255, 255) solid;
    font-size: 16px;
    color: rgb(104, 104, 104);
}

.startprocess-input::placeholder{
    color: rgb(108, 108, 108);
}

.startprocess-actions-cont{
    display: flex;
    justify-content: right;
}

.securer-submit-btn{
    background-color: rgb(85, 104, 200);
    border: none;
    color: white;
    font-size: 14px;
    border-radius: 3px;
    padding: 10px;
}

.securer-submit-btn:hover{
    cursor: pointer;
}

.securer-err{
    color: rgb(237, 70, 70);
    margin-top: 0;
    margin-bottom: 10px;
}

.securer-pending{
    color: rgb(85, 104, 200);
    margin-top: 0;
    margin-bottom: 10px;
}

.securer-mini-headers{
    margin: 5px 0;
    font-size: 18px;
}

.securer-acc-details{
    background-color: rgb(38, 38, 38);
    border: 1px solid rgb(80, 80, 80);
    line-height: 1.5rem;
    border-radius: 3px;
    padding: 8px 10px;
}

.securer-help{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.securer-help:hover{
    cursor: pointer;
}

.securer-help-header{
    margin: 0;
    font-size: 20px;
}

.securer-help-header:hover{
    cursor: pointer;
}

.securer-help-img{
    width: 100%;
    height: 200px;
}

.securer-help-footer{
    margin: 5px 0 0 0;
    font-size: 14px;
    font-weight: bold;
}

.securer-loading
{
    background-color: rgb(85, 104, 200);
    border: none;
    display: flex;
    gap: 5px;
    color: white;
    font-size: 14px;
    border-radius: 3px;
    padding: 10px;
}

.securer-loading-msg{
    margin: auto;
}

.loader {
    margin: auto;
    width: 30px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side,#cdcdcd 90%,#0000) 0/calc(80%/3) 80% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1s steps(4) infinite;
}

@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}

@media only screen and (max-width: 440px) {
    div.securing-modal
    {
        min-width: 100%;
    }
}