.skyblock-page-cont{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    height: 100%;
    margin: 0 auto;
    padding-top: 50px;
}

.s-p-header{
    color: rgb(45, 45, 45);
    display: flex;
    width: 50%;
    margin: auto;
}

.s-p-header-msg{
    margin: auto;
    text-align: center;
    color: rgb(76, 76, 76);
    width: 70%
}

.s-p-header-text{
    font-size: 32px;
    margin: 10px 0;
}

.skyblock-coin-logo{
    width: 60px;
    height: 60px;
    margin: auto 0;
    display: block;
}

.s-c-info-cont
{
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    text-align: left;
}

.s-c-calc-cont
{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    gap: 10px;
}

.s-c-calc-prices{
    display: flex;
    justify-content: space-between;
}

.s-c-calc-header{
    font-size: 24px;
    color: black;
    margin: 15px 0 15px 0;
}

.s-c-calc-amount-cont{
    width: 400px;
    display: flex;
}

.s-c-calc-input-cont{
    display: flex;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    color: rgb(95, 95, 95);
}

.s-c-calc-input{
    border: none;
    width: 300px;
    font-size: 20px;
    outline: none;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    color: rgb(95, 95, 95);
}

.s-c-calc-million{
    padding: 10px;
    margin: auto 0;
    box-sizing: border-box;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(30, 30, 30);
    color: rgb(255, 255, 255);
}

.s-c-calc-amount-msg{
    margin: 5px 0;
    color: rgb(84, 84, 84);
    font-size: 12px;
    font-weight: normal;
}

.s-c-calc-total-cont{
    margin: auto 0;
}

.s-c-calc-total-amount{
    font-weight: normal;
}

.s-c-calc-purchase{
    background-color: rgb(16, 15, 23) ;
    color: rgb(208, 230, 216);
    padding: 10px;
    margin: auto;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}

.s-c-calc-payment-methods{
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    padding-bottom: 50px;
    border-bottom: 2px solid rgb(52, 52, 52);
    gap: 10px;
}

.s-c-calc-sub-header{
    font-size: 18px;
    color: rgb(47, 47, 47);
    font-weight: bold;
}

.s-c-calc-p-m-options{
    max-width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.s-c-calc-p-m-option{
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    font-size: 15px;
    gap: 5px;
    padding: 10px 20px;
    color: rgb(68, 66, 66);
}

.s-c-calc-option-discount{
    font-weight: bold;
    color: rgb(11, 157, 11);
}

.s-c-calc-option-fee{
    font-weight: bold;
    color: rgb(151, 13, 13);
}

.s-c-calc-rates{

}

.s-c-calc-rates-list{
    color: rgb(69, 69, 69);
    display: flex;
    margin: 5px 0;
    flex-direction: column;
    gap: 5px;
}

.s-c-calc-rates-list>div{
    color: rgb(117, 117, 117);
}

.s-c-pm-chosen{
    background-color: rgb(45, 40, 34);
    color: rgb(255, 255, 255);
}

.s-c-calc-extra-info{

}