.member-login-cont{
    width: 450px;
    padding: 30px;
    box-sizing: border-box;
    margin: 110px auto;
    border: 1px silver solid;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: rgb(248, 248, 248);
}

.s-r-or-msg{
    padding: 10px;
    margin: auto;
    color: rgb(105, 105, 105);
}

.s-r-google-sign-in-cont
{
    cursor: pointer;
    border-radius: 3px;
    margin: auto;
    padding: 4px;
    justify-content: right;
    display: flex;
    width: 50%;
    background: #4285F4;
    color: #FFF;
    font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight: 500;
}

.s-r-google-logo-cont{
    background-color: white;
    padding: 8px;
}

.s-r-google-logo{
    width: 15px;
    height: 15px;
}

.s-r-header-cont{

}

.s-r-header{
    margin: 0;
    color: rgb(61, 61, 61);
}

.s-r-google-msg{
    margin: auto;
}

.s-r-h-msg{
    font-size: 12px;
    margin: 10px 0;
    color: rgb(168, 168, 168);
}

.s-r-input-cont{
    width: 100%;
    margin: 10px 0;
}

.s-r-input-cont-main{

}

.s-r-input-header{
    color: rgb(61, 61, 61);
    font-size: 15px;
    margin: 10px 0;
}

.s-r-input{
    width: 100%;
    color: rgb(118, 118, 118);
    box-sizing: border-box;
    border: 1px silver solid;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    padding: 14px;
}

.s-r-input:hover{
    border-color: rgb(92, 120, 192);
}

.s-r-input:focus{
    border-color: rgb(92, 120, 192);
    outline: rgb(109, 136, 206);
}

.s-r-agree-cont{
    padding: 0;
    margin: 5px 0 15px;
    font-size: 14px;
    color: rgb(149, 149, 149);
}

.s-r-agree-main{
    display: flex;
    gap: 5px;
}

.s-r-checkbox{
    border-radius: 5px;
    border: 2px solid silver;
    padding: 4px;
    background-color: rgb(248, 248, 248);
    width: 20px;
    height: 20px;
    margin: auto 0;
    cursor: pointer;
}

.register-tos-link{
    color: rgb(72, 96, 217);
}

.register-tos-link:visited{
    color: rgb(72, 96, 217);
}

.s-r-checkbox:checked {
    background-color: silver;
}

.s-r-checkbox-msg{
    margin: auto 0;
    display: block;
}

.s-r-submit-btn{
    background-color: rgb(109, 136, 206);
    border: none;
    border-radius: 3px;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.s-r-footer{
    width: 100%;
    margin: 20px 0 0;
    text-align: left;
    font-size: 14px;
    color: rgb(149, 149, 149);
}

.s-r-switch{
    color: rgb(74, 95, 180);
    font-weight: bold;
}

.s-r-forgot-password{
    text-align: right;
    width: 100%;
    font-weight: bold;
    margin: 10px 0 20px;
    font-size: 14px;
    color: rgb(74, 95, 180);
}

.s-r-forgot-password:visited{
    color: rgb(74, 95, 180);
}

.s-r-switch:visited{
    color: rgb(74, 95, 180);
}

.s-r-error{
    font-size: 12px;
    display: none;
    margin: 10px 0 0;
    color: red;
}

.s-r-error-list{
    padding: 0 20px;
}

.s-r-message {
    color: red;
    font-size: 16px;
    text-align: center;
    margin: 10px 0 0 0;
}

@media only screen and (max-width: 475px) {
    .member-login-cont{
        margin: 100px auto;
        width: 375px;
        padding: 20px
    }

    .s-r-google-sign-in-cont{
        width: 200px;
    }
}