.member-dashboard-page{
    background-color: rgb(55, 55, 55);
    height: 100%;
    min-height: 100vh;
}

.member-dashboard-cont
{
    margin: auto;
    width: 80%;
    padding: 50px 0;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.m-dashboard-item{
    background-color: rgb(74, 74, 74);
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
}

.hover-box {
    position: relative;
    display: inline-block;
    margin: auto 0;
    cursor: pointer;
    font-size: 16px;
    color: gray;
}

.tooltip {
    visibility: hidden;
    width: 150px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.hover-box:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.m-dashboard-orders-cont{

}

.m-dashboard-browse-products-link{
    color: rgb(168, 187, 232);
}

.m-dashboard-browse-products-link:visited{
    color: rgb(168, 187, 232);
}

.m-dashboard-info-cont{

}

.m-dashboard-item-header{
    margin: 0;
    padding: 20px;
    font-size: 16px;
    border-bottom: 1px silver solid;
    padding-bottom: 20px;
}

.m-dashboard-no-orders-cont{
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.m-dashboard-no-orders-img{
    width: 100px;
    height: 100px;
    margin: auto;
}

.m-dashboard-no-orders-msg{
    font-size: 20px;
    text-align: center;
    margin: auto;
}

.m-dashboard-orders{
    display: flex;
    gap: 15px;
    padding: 20px;
    flex-direction: column;
}

.m-orders-preview{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.m-orders-preview-header{
    padding: 10px;
    background-color: rgb(32, 32, 32);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.m-orders-preview-header-item
{
    gap: 7px;
    display: flex;
}

.m-orders-preview-header-title{
    font-size: 14px;
    color: rgb(130, 130, 130);
}

.m-orders-preview-header-content{
    font-size: 14px;
    text-align: center;
    color: rgb(145, 145, 145);
    font-weight: bold;
}

.m-orders-info{
    padding: 15px;
    display: flex;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    gap: 10px;
    background-color: rgb(47, 47, 47);
    box-sizing: border-box;
    justify-content: space-between;
}

.m-orders-preview-cont{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.m-order-preview-img-cont{
}

.m-order-preview-img{
    width: 80px;
}

.m-order-preview-info-cont{
    display: flex;
    gap: 30px;
}

.m-order-preview-info{
    font-weight: bold;
    font-size: 16px;
}

.m-o-p-details{
    display: flex;
    gap: 40px;
}

.m-o-p-d-item{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.m-o-p-d-title{
    font-size: 15px;
    color: rgb(151, 150, 150);
}

.m-o-p-d-content{
    font-weight: bold;
    font-size: 14px;
}

.m-order-preview-actions{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.m-order-preview-action-item{
    text-align: left;
    width: 100%;
    padding: 7px 20px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    color: rgb(113, 114, 115);
    box-sizing: border-box;
    border: 1px solid silver;
}

.m-order-preview-action-item:visited{
    color: rgb(113, 114, 115);
}

.m-order-preview-action-item:hover{
    cursor: pointer;
    background-color: rgb(237, 237, 237);
}

.m-dashboard-account-info-cont{
    padding: 30px 0;
    display: flex;
}

.m-d-a-i-item{
    display: flex;
    width: 200px;
    gap: 20px;
    flex-direction: column;
    border-right: 1px solid silver;
    padding: 20px 30px;
}

.m-d-a-i-item-img-cont{
    width: 46px;
    height: 46px;
    border-radius: 23px;
    margin: 5px auto;
    display: flex;
    background-color: rgb(75, 75, 75);
}

.total-spent-img-cont{
    background-color: rgb(52, 92, 224);
}

.order-status-complete{
    color: rgb(50, 185, 50);
}

.total-orders{
    background-color: rgb(125, 125, 125);
}

.reward-img-cont{
    background-color: rgb(50, 185, 50);
}

.m-d-a-i-item-img{
    display: block;
    width: 25px;
    height: 25px;
    margin: auto;
}

.m-d-a-i-item-info{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.m-d-a-i-item-header{
    margin: 0;
    font-size: 14px;
    color: rgb(161, 161, 161);
}

.m-d-a-i-item-contents{
    font-size: 22px;
    display: flex;
    gap: 10px;
    font-weight: bold;
}

@media only screen and (max-width: 600px)
{
    div.m-order-preview-img-cont{
        display: none;
    }
}

@media only screen and (max-width: 750px)
{
    .member-dashboard-cont{
        width: 95%;
    }

    .m-dashboard-orders{
        padding: 10px;
    }
}