.products-loading{
  margin: auto;
  width: 85%;
}

.products-loading-msg{
  font-size: 16px;
  text-align: center;
  padding: 10px;
}

.store-cont{
  width: 100%;
  padding-top: 30px;
  margin: 0 auto;
  text-align: left;
  letter-spacing: 1px;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.product-section{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px auto;
  max-width: 100%;
  text-align: center;
}

.product-section-category-cont{
  display: none;
  margin: 0px 10px 0 10px;
  padding: 10px 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow-x: auto;
  gap: 10px;
}

.ps-nav-bar-item{
  display: inline-block;
  border-radius: 5px;
  padding: 10px 17px;
  background-color: rgb(250, 250, 250);
  border: 2px solid rgb(124, 124, 124);
  color: rgb(124, 124, 124);
}

.product-section-row{
    width: 100%;
    margin: auto;
}

.product-section-contents{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  width: 90%;
}

.grid-item-info-slideshow{
  color: white;
  padding: 10px 0 5px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  border-top: none;
  flex-direction: column;
}

.grid-item-header-slideshow{
  color: white;
  display: flex;
  justify-content: center;
}

.section-header{
  margin: 0;
  letter-spacing: normal;
  text-align: left;
  color: rgb(56, 56, 56);
  font-size: 25px;
}

.store-grid-container{
  overflow-x: auto;
  border-radius: 10px;
  gap: 30px;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.store-grid-container::-webkit-scrollbar {
  width: 20px;
}

.store-grid-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

.store-grid-container::-webkit-scrollbar-thumb{
  background: rgb(139, 136, 142);
  border-radius: 5px;
}

.product-price{
  margin: auto 0;
  font-size: 17px;
  font-weight: bold;
}

.product-price-real
{
  text-decoration: line-through;
  font-size: 14px;
  color: rgb(61, 61, 61);
}

.product-mini-details
{
  display: flex;
  width: 90%;
  margin: auto auto 0;
  justify-content: left;
  grid-gap: 5px;
  box-sizing: border-box;
  padding: 5px 0;
}

.featured-product-cont{
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0 10px;
  margin: auto;
}

.featured-product-slider{
  display: flex;
  overflow-x: auto;
  gap: 30px;
  padding: 10px;
}

.featured-product-holder{
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 5px;
  box-sizing: border-box;
  margin: auto 0;
}

.featured-product-img{
  border-radius: 10px;
  width: 240px;
  height: 280px;
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
}

.featured-product-img:hover{
  width: 255px;
  height: 295px;
}

.featured-product-price-cont{
  background: linear-gradient(to right, rgb(85, 85, 85) , rgb(47, 47, 47));
  width: 60%;
  padding: 5px 0;
  margin: auto;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  gap: 5px;
}

.featured-product-price{
  font-size: 16px;
  color: rgb(228, 228, 228);
  text-align: right;
}

.featured-product-discount{
  color: rgb(207, 207, 207);
  font-size: 14px;
  margin-bottom: auto;
  text-decoration: line-through;
}

.grid-item{
  border-radius: 5px;
  display: flex;
  max-width: 270px;
  min-height: 260px;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.grid-item:hover{
  box-shadow: 0px 0px 10px gray;
  opacity: 0.9;
  cursor: pointer;
}

.grid-item button:hover{
  opacity: 0.8;
}

.product-img-holder
{
  border: 1px solid rgb(207, 207, 207);
  background-color: rgb(227, 227, 227);
  box-sizing: border-box;
  padding: 30px 40px;
}

.product-thumbnail-img{
  display: flex;
  margin: auto;
  box-sizing: border-box;
  border: 2px solid rgb(93, 93, 93);
  height: 230px;
  width: 190px;
}

.grid-item-header {
  display: flex;
  justify-content: center;
}

.grid-item-info{
  padding: 10px 0 5px;
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  border-top: none;
  flex-direction: column;
  color: rgba(43, 43, 46, 0.8);
}

.g-i-h-title{
  width: 90%;
  color: rgba(48, 48, 48, 0.89);
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  padding: 0 0 0 0;
}

.grid-item h3{
  font-size: 14px;
  line-height: 30px;
  color: #c7c7c7;
  margin: 0 0 5px 0;
  text-align: center;
  padding: 0 15px;
}

@media only screen and (max-width: 1150px) {
  main{
      width: 90%;
  }

  .grid-item
  {
      font-size: 30px;
      border: 20px;
      box-shadow: 0px 2.0px 10px rgb(142, 137, 137);
  }

  a.shop-a
  {
      display: none;
  }

  .product-section{
    max-width: 100%;
  }

  .section-header{
    width: 100%;
  }

  .store-grid-container{
    background-color: rgb(0,0,0,0);
    width: 100%;
  }
}

@media only screen and (max-width: 750px){
  main{
    max-width: 85%;
  }
}

@media only screen and (max-width: 550px)
{
  .grid-item{
    margin: 0 auto 0 0;
  }

  .stats-finder-item{
    margin: 0 auto 0 0;
  }

  .section-header{
    padding: 10px 0 0;
    width: 100%;
  }
}

/* STATS FINDER CSS*/
.stats-finder-header{
  width: 100%;
}

.stats-finder-item{
  border-radius: 5px;
  min-width: 280px;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgb(172, 134, 70);
  background: url("../images/specialbackground.webp") no-repeat center ;
  background-size: cover;
  text-align: left;
}

.stats-finder-item:hover{
  box-shadow: 0px 0px 10px rgb(207, 199, 185);
}

.stats-finder-title{
  width: 80%;
  color: rgba(255, 255, 255, 0.89);
  margin: auto;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid rgb(180, 180, 180);
  padding: 15px 15px 10px 0;
}

.stats-finder-info{
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: auto;
  width: 100%;
  flex-direction: column;
  background-color: rgba(181, 144, 74, 0.8);
}

.stats-img-holder{
  margin: auto;
}

.stats-img-holder>img{
  margin: auto;
  width: 130px;
  height: 90px;
}

.stats-mini-details{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto auto 0;
  justify-content: left;
  grid-gap: 5px;
  box-sizing: border-box;
  padding: 12px 0;
}

.stats-finder-desc-header{
  font-size: 16px;
  color: white;
  margin: 0;
}

.stats-finder-description{
  margin: 0 auto 5px;
  font-size: 14px;
  color: #e6e5e5;
}

.stats-finder-search{
  background-color: #cabcaf;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.slideshow-cont 
{
  margin: 0 auto;
  width: 100%;
  height: 550px;
}

.slideshow
{
  display: flex;
  background: url("../images/darksky.webp") no-repeat center ;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: rgb(21, 21, 21);
  text-decoration: none;
  color: white;
  position: relative;
  text-shadow: 0px 2.0px 10px rgb(69, 69, 69);
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
}

.slideshow-screener{
  position: absolute;
  justify-content: space-around;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: rgba(88, 88, 88, 0.15);
}

.slideshows::before{
  content: "";
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.slideshow-contents{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.slideshows-words{
  display: flex;
  flex-direction: column;
  min-width: 580px;
  width: 70%;
  padding: 130px 40px 0 80px;
}

.search-button{
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: rgb(19, 7, 89);
  font-weight: bold;
  box-shadow: 0 0 2px 1px rgb(173, 153, 196);
  color: white;
}

.stats-finder-desc{
  line-height: 25px;
  width: 85%;
}

.slideshow-header {
  text-align: left;
  width: 90%;
  max-width: 480px;
  margin: 0;
  font-size: 34px;
}

.slideshow-message{
  line-height: 24px;
  width: 100%;
  max-width: 650px;
}

.slideshow-button{
  cursor: pointer;
  padding: 15px 20px;
  color: white;
  width: 140px;
  text-align: center;
  display: block;
  background-color: rgb(177, 141, 75);
  border-radius: 5px;
}

.slideshows-image
{
  animation-duration: 6s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: move;
  animation-timeline: auto;
}

@media only screen and (max-width: 1550px)
{
  .product-section-category-cont{
    display: flex;
  }

  .store-cont{
    padding-top: 30px;
  }

  div.slideshow-cont {
      width: 100%;
      height: 520px;
  }

  .featured-product-cont{
    margin: 0 auto auto 0;
  }
  
  .slideshow
  {
    display: flex;
    position: relative;
    border-radius: 0;
    margin: auto;
  }

  .slideshows-words{
    box-sizing: border-box;
    padding: 20px;
    gap: 10px;
    min-width: 100%;
    width: 100%;
  }

  .slideshow-screener{
    flex-direction: column;
  }

  .slideshow-header{
    width: 100%;
    max-width: 100%;
    font-size: 20px;
  }

  .slideshow-button{
    padding: 10px;
    font-size: 16px;
  }

  .slideshow-message{
    display: none;
  }
}

@media only screen and (max-width: 890px)
{
  .slideshow-screener{
    flex-direction: column;
  }
}