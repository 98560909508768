.statfinder-cont
{
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: 1;
    margin: auto;
    left: 0;
    top: 0;
    background-color:#0b0b0b;
}

.stats-header-cont
{
    display: flex;
    gap: 10px;
    width: 95%;
    padding: 5px 0;
    box-sizing: border-box;
    border-bottom: 2px solid rgb(200, 202, 249);
    margin: 0 auto;
}

.stats-header-cont-header
{
    margin: auto 0;
    color: rgb(203, 203, 203);
    font-size: 20px;
    text-align: left;
}

.special-accounts-filter-cont{

}

.special-accounts-filter-item {
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgb(38, 38, 38);
    color: white;
    white-space: nowrap;
}

.special-accounts-filter-item:hover {
    background-color: #555;
}

.special-accounts-filter-item.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.statfinder-modal
{
    color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 35px;
}

.special-accounts-filter-cont{
    display: flex;
    overflow: auto;
    margin: 10px auto;
    padding: 0;
    width: 95%;
    gap: 5px;
}

.selected-filters{
    margin: 0 auto;
    width: 90%;
}

.sf-main-cont{
    width: 95%;
    margin: 0 auto;
    display: flex;
    gap: 10px;
}

.sf-accounts-preview-cont{
    display: flex;
    gap: 10px;
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
}

.sf-no-matches{
    margin: 250px auto;
    font-size: 40px;
    text-align: center;
}

.sf-no-matches>p{
    margin: auto;
    font-size: 20px;
}

.vip{
    color: #55FF55 ;
}

.mvp{
    color: #55FFFF ;
}

.mvp_plus_plus{
    color: #FFAA00 ;
}

@media only screen and (max-width: 1350px)
{
    .statfinder-modal{
      min-width: 100%;
    }

    .sf-accounts{
        width: 100%;
    }
}

@media only screen and (max-width: 700px){
    .stats-header-cont-header{
        font-size: 18px;
    }

    .sf-accounts-preview-cont{
        padding: 0;
        gap: 10px;
    }
}