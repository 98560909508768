.seo-cont
{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 50px auto;
}

.seo-header{
    margin: 0;
    color: rgb(48, 48, 48);
    font-size: 24px;
}

.seo-content{
    line-height: 1.3rem;
    font-size: 15px;
    color: rgb(58, 58, 58);
}