.checkout-page{
    background-color: rgb(0, 0, 0);
}

.checkout-cont{
    display: flex;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;
}

.checkout-header{
    width: 100%;
    font-size: 20px;
    font-weight: bold;
}

.checkout-main-cont{
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: space-between;
}

.checkout-empty-cont{
    margin: 150px auto;
}

.o-p-is-empty-msg{
    font-size: 30px;
    font-weight: bold;
}

.o-p-is-empty-img{
    display: block;
    width: 200px;
    margin: auto;
}

.o-p-is-browse-products{
    margin: auto;
    text-align: center;
}

.o-p-is-browse-products-link{
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;
    color: rgb(33, 142, 49);
}

.o-p-is-browse-products-link:visited{
    color: rgb(33, 142, 49);
}

.o-p-i-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.o-p-order-summary{
    
}

.checkout-items-cont{
    color: white;
    display: flex;
    gap: 20px;
    margin: 90px;
    width: 50%;
    flex-direction: column;
}

.checkout-header-cont{
    margin: 0 auto;
    width: 80%;
    display: flex;
}

.checkout-header-go-back-cont{
    cursor: pointer;
    margin: auto 0;
    background-color: rgb(126, 126, 126);
    border-radius: 5px;
}

.stats-header-cont-header{
    margin: auto 0;
}

.checkout-header-go-back{
    margin: auto;
    color: white;
}

.checkout-header-header{
    font-size: 24px;
    margin: 10px auto;
}

.checkout-items-products{
    display: flex;
    width: 80%;
    margin: 0 auto;
    flex-direction: column;
    gap: 20px;
}

.o-p-i-p-item{
    display: flex;
    gap: 20px;
    width: 90%;
    justify-content: space-between;
}

.o-p-i-p-info{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.o-p-i-p-header{
    margin: 0;
    width: 100%;
    font-size: 16px;
}

.o-p-i-p-info-img{
    object-fit: cover;
    width: 90px;
    height: 90px;
}

.o-p-i-p-price{
    font-size: 18px;
    margin: auto 0;
}

.o-p-i-p-remove{
    padding: 6px 10px;
    color: rgb(78, 78, 78);
    border-radius: 2px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    margin: auto;
}

.o-p-i-p-remove:hover{
    cursor: pointer;
}

.o-p-i-p-actions{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.o-p-i-p-quantity{
    font-size: 14px;
    color: gray;
}

.checkout-items-r-header{
    width: 100%;
    margin-top: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.checkout-items-recommendations{
    display: flex;
}

.checkout-page-right-cont{
    width: 40%;
    background-color: rgb(38,38,38);
}

.checkout-page-payment-cont{
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 100px auto 100px 70px;
    box-sizing: border-box;
    width: 50%;
    gap: 20px;
    min-width: 360px;
}

.o-p-o-s-header{
    font-weight: bold;
    color: rgb(226, 226, 226);
    font-size: 16px;  
}

.o-p-p-m-header{
    font-weight: bold;
    color: rgb(226, 226, 226);
    font-size: 14px;
}

.o-p-p-m-pricing{
    display: flex;
    border-top: 1px solid rgb(76, 76, 76);
    border-bottom: 1px solid rgb(76, 76, 76);
    flex-direction: column;
    padding: 15px 0;
    gap: 15px;
}

.o-p-pricing-title{
    color: rgb(187, 187, 187);
    font-size: 15px;
    font-weight: bold;
}

.o-p-pricing-value{
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.o-p-p-m-pricing-option{
    display: flex;
    justify-content: space-between;
}

.o-p-p-m-pricing-discount{
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(126, 126, 126);
}

.o-p-total-cont{

}

.o-p-t-title{
    color: white;
    font-weight: bold;
    font-size: 14px;
    margin: auto 0;
}

.o-p-t-value{
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.o-p-p-m-promo{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.o-p-p-m-promo-title{
    display: block;
    margin: auto 0;
}

.o-p-p-m-promo-submit-cont{
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.o-p-p-m-promo-input{
    padding: 7px 11px;
    font-size: 14px;
    color: rgb(112, 112, 112);
    border: 1px solid rgb(144, 144, 144);
    width: 85%;
    box-sizing: border-box;
}

.o-p-p-m-promo-input:focus{
    border: rgb(63, 63, 63) 1px solid;
    color: rgb(62, 62, 62);
    outline: none;
}

.fa-tags{
    color: black;
}

.o-p-p-m-promo-submit{
    width: 15%;
    display: flex;
    border: 1px solid rgb(144, 144, 144);
    padding: 5px 5px 5px 2px;
    background-color: none;
}

.o-p-p-m-promo-submit:hover{
    background-color: rgb(198, 198, 198);
}

.o-p-p-m-promo-submit-text{
    font-size: 12px;
    margin: auto;
    color: rgb(103, 103, 103);
}

.o-p-p-m-tag-img{
    width: 20px;
    margin: auto;
}

.o-p-customer-name{
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: rgb(70, 70, 70);
    gap: 12px;
}

.o-p-customer-inputs-box{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.o-p-customer-inputs-header{
    font-size: 14px;
    color: rgb(188, 188, 188);
}

.o-p-customer-input-cont{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
}

.o-p-customer-input
{
    font-size: 15px;
    width: 100%;
    padding: 12px 10px;
    border-radius: 5px;
    background-color: rgb(60, 60, 60);
    border: none;
    color: rgb(220, 220, 220);
    box-sizing: border-box;
}

.o-p-customer-input-disabled{
    font-size: 14px;
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid rgb(248, 248, 248);
    font-size: 15px;
    box-sizing: border-box;
    background-color: rgb(140, 140, 140);
    color: rgb(222, 222, 222);
    cursor: not-allowed;
}

.o-p-customer-input:focus{
    border: 1px solid rgb(111, 111, 111);
    outline: none;
}

.o-p-customer-input-error{
    border: 1px solid red;
}

.o-p-payment-methods{
    display: flex;
    gap: 7px;
    flex-direction: column;
}

.o-p-payment-method-option{
    display: flex;
    gap: 10px;
    background-color: rgb(58, 58, 58);
    padding: 6px;
    border: 1px solid rgb(74, 74, 74);
    border-radius: 5px;
    color: rgb(187, 187, 187);
    justify-content: space-between;
    cursor: pointer;
}

.o-p-pm-selected{
    background-color: rgb(6, 6, 10);
    border: 1px solid rgb(26, 25, 25);
    color: white;
}

.o-p-payment-method-option:hover{
    background-color: rgb(6, 6, 10);
    color: white;
}

.o-p-payment-method-option-text
{
    font-size: 14px;
    margin: auto 0;
}

.o-p-payment-method-option{
}

.o-p-pm-main-cont
{
    display: flex;
    gap: 7px;
}

.o-p-pm-main-fee{
    color: red;
}

.o-p-pm-main-discount{
    color: rgb(28, 179, 56);
}

.o-p-pm-main-price-change
{
    margin: auto 0;
    background-color: rgb(12, 12, 12);
    padding: 6px 7px;
    font-size: 14px;
    border-radius: 5px;
    box-sizing: border-box;
}

.o-p-payment-method-option-img
{
    width: 30px;
    height: 30px;
    margin: auto;
}

.o-p-customer-checkout{
    width: 100%;
    text-align: center;
    background-color: rgb(36, 134, 108);
    box-sizing: border-box;
    color: white;
    border-radius: 5px;
    padding: 15px;
}

.o-p-customer-checkout:hover{
    background-color: rgb(121, 123, 126);
    cursor: pointer;
}

.o-p-loading-pay{
    justify-content: center;
    display: flex;
    background-color: rgb(105, 135, 220);
    color: white;
    box-sizing: border-box;
    padding: 15px;
    border-radius: 5px;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    margin: auto 15px;
    width: 18px;
    height: 18px;
}

.lds-spinner div {
    transform-origin: 10px 10px;
    animation: lds-spinner 1.2s linear infinite;
}
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 20px;
    width: 3px;
    height: 4px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

@media only screen and (max-width: 1300px) {
    div.checkout-items-cont{
        width: 40%;
        flex-direction: column;
    }

    div.checkout-page-payment-cont{
        min-width: 300px;
        margin: 100px auto auto 30px;
    }

    div.checkout-main-cont{
        min-height: 100vh;
        height: 100%;
    }

    /* div.checkout-main-cont{
        flex-direction: column;
        gap: 0;
    }

    div.checkout-p-m-cont{
        width: 100%;
        margin: auto;
    }

    div.checkout-items-recommendations{
        display: none;
    } */
}

@media only screen and (max-width: 900px) {
    div.checkout-main-cont{
        flex-direction: column;
        gap: 0;
    }

    div.checkout-items-cont{
        width: 100%;
        margin: 100px 0 40px;
        flex-direction: column;
    }

    div.checkout-page-payment-cont{
        min-width: 300px;
        margin: 50px auto 100px ;
    }

    div.checkout-page-right-cont{
        width: 100%;
        height: 100vh;
    }
}

@media only screen and (max-width: 600px) 
{
    div.checkout-header-cont{
        margin: 0 auto;
        width: 85%;
        display: flex;
    }

    div.checkout-items-products{
        display: flex;
        width: 85%;
        margin: 0 auto;
        flex-direction: column;
        gap: 20px;
    }
    

    h1.checkout-header-header{
        margin: auto 10px;
        font-size: 18px;
    }

    div.checkout-items-cont{
        margin-top: 70px;
    }

    div.checkout-page-payment-cont{
        min-width: 350px;
        margin: 20px auto 100px ;
        gap: 10px;
    }

    svg.checkout-header-go-back{
        width: 20px;
        height: 18px;
    }
}