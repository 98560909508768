@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

.box {
    --border-size: 3px;
    --border-angle: 0turn;
    background-image: conic-gradient(
        from var(--border-angle),
        #213,
        #112 50%,
        #213
      ),
      conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
    background-size: calc(100% - (var(--border-size) * 2))
        calc(100% - (var(--border-size) * 2)),
      cover;
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: none;
    animation: bg-spin 3s linear infinite;
}

.product-page-cont{
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 50px;
}

.product-page-top-segment{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: auto;
    border-bottom: 2px rgb(62, 62, 62) solid;
}

.product-page-go-back-cont{
    display: flex;
    cursor: pointer;
}

.product-page-go-back-text{
    margin: auto 0;
    letter-spacing: .03em;
    font-size: 12px;
    font-weight: bold;
    color: black;
}

.find-btn{
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.1rem;
    padding: 12px 16px;
    margin: auto;
    width: 100%;
    border-radius: 5px;
    bottom: 0;
    box-shadow: 0px -0.5px 4px 2px rgb(179, 127, 232);
    background-image: linear-gradient(to left, #213, #213);
    color: white;
}


.p-p-product-details-header{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 0;
    font-size: 18px;
    color: rgb(41, 41, 41);
    text-align: center;
    font-weight: bold;
}

.p-p-info-mini{
    margin-bottom: 15px;
}

.p-p-m-info{
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    text-align: left;
}

.p-p-m-header{
    color: rgb(45, 45, 45);
    font-size: 30px;
    padding: 10px 0;
    margin: 0;
}

.p-p-m-bonus{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    list-style: none;
    padding: 0;
    grid-gap: 10px;
    margin: 0 0 10px 0;
}

.p-p-m-bonus>li{
    background-color: rgb(86, 84, 90);
    color: rgb(245, 245, 245);
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 13px;
}

.p-p-m-description{
    color: rgb(86, 86, 86);
    line-height: 1.5rem;
    font-size: 15px;
    margin: 15px 0;
    width: 90%;
}

.p-p-main
{
    display: flex;
    gap: 30px;
    padding-bottom: 30px;
}

.s-c-p-prices{
    display: flex;
    gap: 10px;
}

.s-c-p-price-cont{
    display: flex;
    flex-direction: column;
}

.s-c-p-price-display{
    display: flex;
    gap: 5px;
}

.s-c-p-main-price{
    margin: auto 0;
    font-size: 20px;
    font-weight: bold;
    color: rgb(53, 53, 53);
}

.s-c-p-dashed-price{
    text-decoration: line-through;
    font-size: 15px;
    color: rgb(61, 61, 61);
}

.s-c-p-percent-off{
    background-color: rgb(240, 212, 212);
    display: inline-block;
    width: 40px;
    color: rgb(189, 0, 0);
    padding: 3px;
    font-size: 12px;
    border: 1px solid rgb(171, 0, 0);
    border-radius: 5px;
}

.s-c-p-discounts{
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.s-c-p-discount-list{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.s-c-p-discount-detail{
    font-size: 14px;
    color: rgb(94, 94, 94);
}

.s-c-p-discount-enchance
{
    color: black;
}

.button-div{
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 10px;
}

.purchase
{
  font-size: 16px;
  font-weight: bold;
  margin: auto 0;
  letter-spacing: 0.1rem;
  padding: 15px 20px;
  border-radius: 5px;
  box-sizing: border-box;
  bottom: 0;
  background: rgb(0, 0, 0);
  color: white;
}

.s-c-p-quantity-setter {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    font-size: 20px;
    margin: 10px 0;
    padding: 5px;
    width: 100%;
    max-width: 120px; /* Adjust to ensure Safari respects size */
    box-sizing: border-box;
}

.s-c-p-q-b {
    background-color: #2c2c2c;
    border-radius: 5px;
    box-sizing: border-box;
    color: #fff;
    text-align: center;
    font-size: 20px;
    width: 30px;
    min-width: 20px; /* Prevent shrinking */
    border: none;
}

.s-c-p-quantity-i {
    background-color: #ffffff;
    border: none;
    color: #000;
    font-size: 16px;
    text-align: center;
    width: 10%;
    flex-grow: 1;
    padding: 2px;
}

.p-p-m-header-cont{
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
}

.product-img-cont{
    border-radius: 5px;
}

.product-image{
    border-radius: 5px;
    width: 350px;
    height: 440px;
}

.product-image-thumbnail{
    margin: auto;
    width: 90px;
    height: 100px;
}

.p-p-extra{
    margin: 20px 0;
}

.p-p-e-item{
    padding: 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 18px;
    color: rgb(57, 57, 57);
}

.p-p-e-item-instructions{
    margin: 10px 0;
    line-height: 1.5rem;
    font-size: 16px;
}

.p-p-e-item-more-header{
    font-weight: bold;
    font-size: 18px;
    margin: 5px 0x;
}

.p-p-e-features
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 20px;
}

.p-p-e-warranty
{
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    gap: 10px;
}

.p-p-e-features-item, .p-p-e-warranty-item
{
    font-size: 16px;
}

.p-p-e-more>li{
    padding: 5px;
    font-size: 16px;
}

h3.subsection-header{
    text-align: left;
    font-size: 18px;
    margin-bottom: 0;
}

.hypixel-alts{
    
}

.full-access-addons
{
    color: rgb(235, 235, 235);
    margin-bottom: 10px;
}

.fa-addons-options-cont{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.fa-addons-header{
    margin: 10px 0;
    font-size: 18px;
}

.fa-addons-option
{
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid rgb(255, 255, 255);
    display: inline-block;
    background-color: rgb(31, 31, 31);
}

.fa-addons-option:hover{
    cursor: pointer;
    border: 1px solid rgb(136, 36, 36);
}

.fa-addon-option-title{
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.fa-addon-option-price{
    margin: 5px 0 0;
    font-size: 14px;
}

.product-page-mobile-purchase{
    position: fixed;
    bottom: 0;
    left: 0;
    gap: 10px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    margin: 0;
    border-top: 1px solid rgb(3, 3, 3);
    background-color: rgb(216, 216, 216);
    width: 100%;
    box-shadow: 0px -2px 10px 2px rgb(3, 3, 3);
}

.power-by{
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 12px;
}

.product-page-mobile-purchase-actions{
    display: flex;
    padding-bottom: 5px;
    justify-content: space-between;
    box-sizing: border-box;
}

.product-page-mobile-purchase-price{
    margin: auto;
    font-size: 22px;
}

@media only screen and (max-width: 1000px){
    .p-p-main{
        gap: 20px;
        padding: 0 10px 10px 10px;
    }

    .p-p-m-description{
        width: 100%;
    }

    .p-p-extra{
        font-size: 12px;
    }

    .p-p-e-item-instructions{
        font-size: 14px;
    }

    .p-p-e-item{
        padding: 0;
    }

    .p-p-e-more>li{
        padding: 5px 0;
        font-size: 14px;
    }

    .p-p-product-details-header{
        padding: 0;
    }

    .product-page-cont{
        margin: 10px;
        width: 95%;
    }
}

.table-container {
    overflow-x: auto;
    padding: 16px 0;
}
  
    .custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
  
.custom-table th,
.custom-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #d1d5db;
}

.warranty-specs-header{
    padding: 10px;
    background-color: #f3f4f6;
    border: 1px solid #d1d5db;
    border-top: none;
    font-weight: bold;
}

.warranty-specs-cont{
    padding: 10px;
    line-height: 1.5rem;
    background-color: rgb(241, 241, 241);
    border: 1px solid #d1d5db;
    border-top: none;
}

.warranty-specs-cont-header{
    margin: 10px 0;
}

.custom-table th {
    background-color: #f3f4f6;
    color: #374151;
    font-weight: bold;
}
  
.custom-table tr:nth-child(even) {
    background-color: #f9fafb;
}

.supported-devices-cont{
    display: flex;
    box-sizing: border-box;
    gap: 5px;
    margin: 0;
}

.mobile-item{
    display: none;
}

@media only screen and (max-width: 750px)
{
    .p-p-m-header{
        font-size: 22px;
    }    

    .desktop-item
    {
        display: none;
    }

    .mobile-item{
        display: block;
    }

    .product-image{
        width: 250px;
        height: 310px;
        display: block;
    }

    .p-p-m-bonus>li{
        font-size: 12px;
    }

    .p-p-main{
        padding: 0 0 10px 0;
    }

    .p-p-m-description{
        width: 100%;
    }

    .p-p-extra{
        margin: 20px 0;
        font-size: 12px;
    }

    .p-p-e-item-instructions{
        font-size: 14px;
    }

    .p-p-e-item{
        padding: 0;
    }

    .p-p-e-more>li{
        padding: 5px 0;
        font-size: 14px;
    }

    .p-p-product-details-header{
        padding: 0;
    }

    .product-page-top-segment{
        gap: 0;
    }

    .product-page-cont{
        padding-top: 40px;
        width: 95%;
    }
}