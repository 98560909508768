html
{
    scroll-behavior: smooth;
    scroll-padding-top: 150px;
    font-family: Lato, sans-serif;
    margin: 0;
    top: 0;
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
}

body
{
  box-sizing: border-box;
  margin: 0;
  top: 0;
}

.main{
  padding-top: 50px;
  min-height: 100vh;
  height: 100%;
}

a{
    text-decoration: none;
}

button{
    border: none;
}

button:hover{
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera Removing increment from type number for inputs*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:disabled{
    cursor: not-allowed;
    opacity: 0.8;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=submit]:hover {
    cursor: pointer;
}

.bold{
    font-weight: bold;
}

.block{
    display: block;
}

.alerts-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2;
    gap: 20px;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto;
    padding: 0 20px;
  }

.notif-popup{
    width: fit-content;
    text-align: center;
    min-width: 250px;
    justify-content: right;
    gap: 20px;
    top: 0;
    right: 0;
    border-radius: 3px;
    padding: 10px 0;
    animation-name: slidein;
    animation-duration: 1s;
  }

.error-popup{
    background-color: #d56f6c;
    color: #9d1d18;
    border: 1px solid #9d1d18;
  }
  
  .warning-popup{
    background-color: #d4936b;
    color: #9e4b17;
    border: 1px solid #9e4b17;
  }
  
  .success-popup{
    background-color: #7ec17b;
    color: #0f740a;
    border: 1px solid #0f740a;
  }

.notif-popup:hover{
    cursor: pointer;
}

@keyframes blink {
    0%, 100% {
      opacity: 1; /* Fully visible */
    }
    50% {
      opacity: 0; /* Fully invisible */
    }
}

.blinking-text {
    background-color: #ff0000; /* Red color */
    animation: blink 1s infinite; /* Blink every 1 second */
}

@keyframes slidein {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@keyframes move {
    25% {
        opacity: 1;
        transform: translateY(15px)
    }

    75% {
        opacity: 1;
        transform: translateY(30px)
    }
}

@keyframes bounce {
    25% {
        opacity: 1;
        transform: translateY(15px)
    }

    75% {
        opacity: 1;
        transform: translateY(15px)
    }
}

@keyframes slideRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100px); /* Adjust this value to control how far it slides */
    }
}

@media only screen and (max-width: 500px){
    
}